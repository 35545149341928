<template>
  <div>
    <v-navigation-drawer
      color="primary"
      class="app-menu"
      v-model="$store.state.menu"
      app
      :right="rtl"
      width="250"
    >
      <v-list nav dense class="pt-10 list d-done">
        <v-list-item class="white--text" to="/dashboard" link>
          <v-list-item-icon class="me-4" color="white">
            <v-icon class="white--text">home</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">الرئيسية</v-list-item-title>
        </v-list-item>
        <!--/ list item -->

        <v-list-item class="white--text" to="/requests" link>
          <v-list-item-icon class="me-4" color="white">
            <v-icon class="white--text">person_add</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">طلبات الالتحاق</v-list-item-title>
          <v-list-item-action>
            <v-badge
              offset-y="10"
              :content="
                $store.state.requests == 0 ? '0' : $store.state.requests
              "
              :color="$store.state.requests == 0 ? 'grey' : 'success'"
            ></v-badge>
          </v-list-item-action>
        </v-list-item>

        <v-list-item class="white--text" to="/Transfares" link>
          <v-list-item-icon class="me-4" color="white">
            <v-icon class="white--text">person_add</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">طلبات التحويل</v-list-item-title>
          <v-list-item-action>
            <v-badge
              offset-y="10"
              :content="
                $store.state.transfers == 0 ? '0' : $store.state.transfers
              "
              :color="$store.state.transfers == 0 ? 'grey' : 'success'"
            ></v-badge>
          </v-list-item-action>
        </v-list-item>

        <v-list-item class="white--text" to="/Customers" link>
          <v-list-item-icon class="me-4" color="white">
            <v-icon class="white--text">group</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">العملاء</v-list-item-title>
        </v-list-item>
        <!--/ list item -->
        <v-list-item class="white--text" to="/certificates" link>
          <v-list-item-icon class="me-4" color="white">
            <v-icon class="white--text">redeem</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">الشهادات</v-list-item-title>
        </v-list-item>
        <!--/ list item -->

        <v-list-item class="white--text" to="/Certificates_settings" link>
          <v-list-item-icon class="me-4" color="white">
            <v-icon class="white--text">redeem</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white"
            >التحكم فى الشهادات</v-list-item-title
          >
        </v-list-item>
        <!--/ list item -->

        <v-list-item class="white--text" to="/settings" link>
          <v-list-item-icon class="me-4" color="white">
            <v-icon class="white--text">settings</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">الأعدادت</v-list-item-title>
        </v-list-item>
        <!--/ list item -->

        <v-list-item @click="logout()" class="white--text" link>
          <v-list-item-icon class="me-4" color="white">
            <v-icon class="white--text">logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white"> تسجيل الخروج</v-list-item-title>
        </v-list-item>
        <!--/ list item -->
      </v-list>

      <!--/ company -->
    </v-navigation-drawer>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .app-menu {
    .v-navigation-drawer__content {
      .list {
        padding-bottom: 100px !important;
      }
      &::-webkit-scrollbar {
        width: 0;
        scrollbar-width: none;
      }
    }
  }
}
</style>

<script>
export default {
  name: "AsideMenu",
  data() {
    return {};
  },
  computed: {
    rtl() {
      return this.$vuetify.rtl;
    },
  },
  methods: {
    logout() {
      let vm = this;
      this.$store.commit("logout");
      vm.$router.push({ name: "login" });
    },
  },
};
</script>
